import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Banner from "../components/banner"
import Sekcja1 from "../components/sekcja1"
import Sekcja2 from "../components/sekcja2"
import Sekcja3 from "../components/sekcja3"
import Sekcja4 from "../components/sekcja4en"
import Sekcja5 from "../components/sekcja5"
import Sekcja6 from "../components/sekcja6"
import Sekcja7 from "../components/sekcja7en"

import Helmet from "react-helmet"



const Home = ({ data }) => (
  <>
  <Helmet
  title={data.wordpressPage.acf.home_title_seo} 
  titleTemplate={`${data.wordpressPage.acf.home_title_seo}  | Websites : Lemoon Web`}
  meta={[
    {
      name: `description`,
      content: data.wordpressPage.acf.home_description_seo, 
    },
    {
      name: `thumbnail`,
      content: data.wordpressPage.acf.home_img_seo.localFile.url,
    },
    {
      property: `og:title`,
      content: data.wordpressPage.acf.home_title_seo,
    },
    {
      property: `og:image`,
      content: data.wordpressPage.acf.home_img_seo.localFile.url, 
    },
    {
      property: `og:description`,
      content: data.wordpressPage.acf.home_description_seo,
    },
    {
      property: `og:type`,
      content: `website`,
    },
  ]}
/>
<Layout lang="en" pageInfo={{ pageName: "index" }}>   
<Banner lang="en" ban={data.wordpressPage.acf}/>
<Sekcja1 field={data.wordpressPage.acf}/>
<Sekcja2 lang="en" sec2={data.wordpressPage.acf}/>
<Sekcja3 sec3={data.wordpressPage.acf}/>
<Sekcja4 lang="en"/>
<Sekcja5 lang="en" sec5={data.wordpressPage.acf}/>
<Sekcja6 lang="en" sec6={data.wordpressPage.acf}/>
{/* <Sekcja7 homeblog={data.allWordpressPost.nodes}/> */}
</Layout> 
</>
)

export default Home
 export const query = graphql`
 query MyQueryRN {
  wordpressPage(title: {eq: "Home EN"}) {
    acf {
      home_title_seo
      home_description_seo
      home_img_seo {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      section_1_text
      section_1_title
      section_2_button
      section_2_list_sign
      section_2_subtitle
      section_2_text
      section_2_title
      section_3_list_rep {
        text
        title
      }
      section_3_img {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      section_2_list_rep {
        text
      }
      section_2_img_front {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      section_2_img_behind {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      section_3_text
      section_3_title
      section_5_cloud_text
      section_5_list_2_img {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      section_5_list_2_rep {
        text
      }
      section_5_list_rep {
        subtitle
        title
      }
      section_5_subtitle
      section_5_title
      section_6_list_2_img {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      section_6_list_2_rep {
        text
      }
      section_6_list_rep {
        text
        title
      }
      section_6_text
      section_6_title
      section_1_rep {
        text
        img_flat
        link
      }
      section_banner_button
      section_banner_title_large
      section_banner_title_small
      section_banner_img {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
    }
  }
  allWordpressPost(filter: {acf: {lang: {eq: "en"}}}, limit: 3, sort: {fields: date, order: DESC}) {
    nodes {
      content
      date(formatString: "DD MMM")
      slug
      title
      featured_media {
        localFile {
          url
        }
        acf {
          alt_en
        }
      }
      author {
        name
      }
      excerpt
    }
  }
}



 `
