import { Link } from "gatsby"
import React from "react"
import { Container, Row, Col } from 'reactstrap'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-regular-svg-icons'
import { faUserCircle } from '@fortawesome/free-regular-svg-icons'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import ScrollAnimation from 'react-animate-on-scroll';


class SectionSeven extends React.Component {

    render() {
      function excert(text){
        text = text.replace(/<\/?[^>]+(>|$)/g, "");
        text = text.substring(0,  110) + '...';
        return text;
        }
      return (
        <div className="section7">
         <Container>
            <div className='title-cont'><h2 className='title'>Blog news<span className='dot'>.</span></h2></div>
            <Row>

            {this.props.homeblog.map((val, index) => 

            <Col md='4' key={index}>
              <ScrollAnimation animateOnce='true' animateIn='fadeInUp' delay={index * 200}>

                <div className='post-box'>
                  <div className='img-box'>
                    <Link to={'/en/blog/' + val.slug}>
                      <img src={val.featured_media.localFile.url} alt={val.featured_media.acf.alt_en}></img>
                    </Link>
                  </div>
                  <div className='lower-box'>
                    <div className='post-meta'>
                      <ul className='clearfix'>
                        <li>
                          <FontAwesomeIcon className='icons' icon={faClock} />
                          {val.date}
                        </li>
                        <li>
                        <FontAwesomeIcon className='icons' icon={faUserCircle} />
                          {val.author.name}
                        </li>
                      </ul>
                    </div>
                    <h3 className='title'>
                      <Link to={'/en/blog/' + val.slug}>{val.title}</Link>
                    </h3>
                    <div className='text' >
                    { excert(val.content) }
                    </div>
                    <div className='link-box'>
                        <Link to={'/en/blog/' + val.slug} className='theme-btn'>
                      <FontAwesomeIcon className='icon-arr' icon={faChevronRight} />
                      </Link>
                    </div>
                  </div>
                </div>
                </ScrollAnimation>
              </Col> 
                     
            )}

            </Row>
         </Container>
        </div>
      );
    }
  }


export default SectionSeven;
